const CartActionTypes = {
  TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',
  UPDATE_FIRESTORE_CART_SUCCESS: 'UPDATE_FIRESTORE_CART_SUCCESS',
  UPDATE_FIRESTORE_CART_FAILED: 'UPDATE_FIRESTORE_CART_FAILED',
  HYDRATE_CART: 'HYDRATE_CART',
  UPDATE_LOCAL_CART_SUCCESS: 'UPDATE_LOCAL_CART_SUCCESS',
  UPDATE_LOCAL_CART_FAILED: 'UPDATE_LOCAL_CART_FAILED',
  SET_CART_FROM_FIREBASE: 'SET_CART_FROM_FIREBASE',
  UPDATE_CART_IN_FIREBASE_SUCCESS: 'UPDATE_CART_IN_FIREBASE_SUCCESS',
  UPDATE_CART_IN_FIREBASE_FAILED: 'UPDATE_CART_IN_FIREBASE_FAILED',
};

export default CartActionTypes;
